define("interval/modifiers/toast-hammer", ["exports", "ember-paper/modifiers/toast-hammer"], function (_exports, _toastHammer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _toastHammer.default;
    }
  });
});