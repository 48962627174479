define("interval/controllers/application", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object"], function (_exports, _controller, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationController = (_class = class ApplicationController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intervals", _descriptor, this);

      _initializerDefineProperty(this, "startDate", _descriptor2, this);

      _initializerDefineProperty(this, "isAbort", _descriptor3, this);

      _initializerDefineProperty(this, "multifactor", _descriptor4, this);

      _initializerDefineProperty(this, "duration", _descriptor5, this);

      _initializerDefineProperty(this, "isTimerRunning", _descriptor6, this);

      _initializerDefineProperty(this, "timerDone", _descriptor7, this);

      _initializerDefineProperty(this, "currentInterval", _descriptor8, this);

      _initializerDefineProperty(this, "currentIntervalInitial", _descriptor9, this);
    }

    get getDisabled() {
      if (this.intervals.length == 0) {
        return true;
      } else {
        return false;
      }
    }

    get getCurrentInterval() {
      return this.currentIntervalInitial - this.currentInterval;
    }

    setTimer() {
      let intervals = this.intervals;

      for (let counter = 0; counter < this.multifactor; counter++) {
        intervals.push(parseInt(this.duration));
      }

      this.intervals = [];
      this.intervals = intervals;
      this.multifactor = 1;
      this.duration = 10;
    }

    deleteInterval(position) {
      let intervals = this.intervals;
      intervals.splice(position, 1);
      this.intervals = intervals;
    }

    startIntervalTimer() {
      this.isTimerRunning = true;
      this.timerDone = false;
      let intervals = this.intervals;
      var timeleft = intervals[0];
      this.currentIntervalInitial = intervals[0];
      this.currentInterval = intervals[0];
      intervals.splice(0, 1);
      var intervalTimer = setInterval(() => {
        if (timeleft <= 0) {
          console.log("there's no time!");

          if (intervals.length == 0) {
            console.log('and no intervals!');
            intervals.splice(0, 1);
            clearInterval(intervalTimer);
            this.timerDone = true;
            this.isTimerRunning = false;
          } else {
            console.log('found more time!', intervals);
            timeleft = intervals[0];
            this.currentIntervalInitial = intervals[0];
            this.currentInterval = intervals[0];
            intervals.splice(0, 1);
            this.intervals = intervals;
          }
        }

        timeleft -= 1;

        if (timeleft <= 0) {
          this.currentInterval = 0;
        } else {
          this.currentInterval = timeleft;
        }

        if (this.currentInterval < 1) {
          document.getElementById('beep-audio').play();
        }

        console.log("clock's ticking!", this.currentInterval);
      }, 1000);
      this.intervals = intervals;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intervals", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isAbort", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "multifactor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "duration", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 10;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isTimerRunning", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "timerDone", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "currentInterval", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "currentIntervalInitial", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setTimer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setTimer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteInterval", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteInterval"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startIntervalTimer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "startIntervalTimer"), _class.prototype)), _class);
  _exports.default = ApplicationController;
});